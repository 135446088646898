import React, { useState, useEffect } from "react";

import { Box } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { Welcome } from "./Welcome"
import { Camera } from "./Camera";
import { Guidelines } from "./Guidelines";
import { Error } from "./Error";

import { API_ENDPOINT } from "../../api/constants";

const STEPS = {
  WELCOME: "Welcome",
  GUIDELINES: "Guidelines",
  CAMERA: "Camera",
  ERROR: "Error"
}

export const Scenarii = () => {
  const [step, setStep] = useState(STEPS.GUIDELINES)
  const [searchParams, setSearchParams] = useSearchParams()

  const scanPushAnswers = async (answers) => {
    const mid = searchParams.get("mid") || null;
    if (mid) {
      const success = await fetch(`${API_ENDPOINT}scan-push-answers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          measure_id: mid,
          answers: answers
        })
      })
        .then((res) => res.json())
        .then((res) => res)
        .catch((e) => {
          console.log('Error: scanPushAnswers - ', e);
          return false;
        });
      return success;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const answers = {
      age: Number(searchParams.get("age")) || 40,
      weight: Number(searchParams.get("weight")) || 70,
      height: Number(searchParams.get("height")) || 170,
      gender: searchParams.get("gender") || 'male',
    }
    const result = scanPushAnswers(answers).then((links) => {
      if (typeof links?.error !== 'string') {
        localStorage.setItem('front_put_url', links?.front_put_url)
        localStorage.setItem('side_put_url', links?.side_put_url)
      }
    })
  }, [])

  const inclinationPermission = () => {
    if (
        typeof DeviceOrientationEvent !== "undefined" &&
        typeof DeviceOrientationEvent.requestPermission === "function"
    ) {
        DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
            if (permissionState === "granted") {
              setStep(STEPS.CAMERA)
            } else {
              setStep(STEPS.ERROR)
            }
        })
        .catch((e) => {
            if (e !== "") { 
              console.log("e", e);
              setStep(STEPS.ERROR)
            }
        });
    } else if (window.DeviceOrientationEvent) {
      setStep(STEPS.CAMERA)
    } else {
        console.log("Unaccessible");
        setStep(STEPS.ERROR)
    }
  }

  const nextStep = () => {
    if (step === STEPS.WELCOME) {
      setStep(STEPS.GUIDELINES)
    } else if (step === STEPS.GUIDELINES) {
      inclinationPermission()
    }
  }

  const previousStep = () => {
    if (step === STEPS.CAMERA) {
      setStep(STEPS.GUIDELINES)
    }
  }

  return (
    <Box>
      {step === STEPS.WELCOME && (
        <Welcome
          openCamera={nextStep}
        />
      )}
      {step === STEPS.GUIDELINES && (
        <Guidelines 
          nextStep={nextStep}
        />
      )}
      {step === STEPS.CAMERA && (
        <Camera 
          closeCamera={nextStep}
          previousScreen={previousStep}
        />
      )}
      {step === STEPS.ERROR && (
        <Error
          retry={inclinationPermission}
        />
      )}
    </Box>
  );
};
