import valid from "../../../../assets/img/valid.png"
import error from "../../../../assets/img/error.png"

export const outcome = {
    "success": {
        img: valid,
        title: "Parfait !",
        message: "Vos mesures ont bien été prises. Vous allez pouvoir passer à l’étape suivante."
    },
    "error": {
        img: error,
        title: "Oups...",
        message: "Quelque chose s’est mal passé... Vérifiez votre connexion Internet et réessayez."
    }
}