import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
//import Go from "./pages/Camera/Go";
//import CameraError from "./pages/Camera/Error";
//import { Success } from './pages/Success/Success'
//import { InclinationGuide } from "./pages/Calibration/InclinationGuide";
//import { Inclination } from "./pages/Calibration/Inclination";
//import { Caroussel } from "./pages/Guidelines/Caroussel";

function App() {
  const [userInfoSaved, setUserInfoSaved] = useState(false);

  useEffect(() => {
    const userInfo = !!localStorage.getItem("userInfo");
    if (userInfo) {
      setUserInfoSaved(true);
    } else {
      setUserInfoSaved(false);
    }
  }, [userInfoSaved])

  return (
    <div className="App" style={{backgroundColor: 'white'}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main userInfoSaved={userInfoSaved} setUserInfoSaved={setUserInfoSaved} />} />
          {/* 
            <Route
              path="/calibration"
              element={<Inclination hasVisited={hasVisited} />}
            />
            <Route 
              path="/go" 
              element={<Go hasVisited={hasVisited} />} 
            />
            <Route 
              path="/caroussel" 
              element={<Caroussel />} 
            />
            <Route 
              path="/inclination_guide" 
              element={<InclinationGuide />} 
            />
            <Route 
              path="/success" 
              element={<Success />} 
            />
            <Route 
              path="/error" 
              element={<CameraError />} 
            />
          */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
