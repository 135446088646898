import { Box } from "@mui/material"

import { ArrowBackIosNew } from '@mui/icons-material';

export const GoBack = ({ 
    onClick 
}) => {
    return (
        <Box
            onClick={onClick}
            sx={{
                position: "absolute",
                zIndex: 9999999,
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "lightgrey",
                borderRadius: "40px",
                margin: "20px"
            }}
        >
            <ArrowBackIosNew sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "5px"
            }}/>
        </Box>
    )
}