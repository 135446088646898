import { useState, useEffect } from "react"

import { Box } from "@mui/material"

import { Calibrate } from "./Calibrate"
import { Animation } from "./Animation"

import { GoBack } from "../../../../molecules/GoBack"

const STEPS = {
    ANIMATION: "Animation",
    CALIBRATE: "Calibrate"
}

export const Inclination = ({
    startTutorial,
    previousScreen
}) => {
    const [step, setStep] = useState(STEPS.ANIMATION)

    const nextStep = () => {
        if (step === STEPS.ANIMATION) {
            setStep(STEPS.CALIBRATE)
        }
    }

    const previousStep = () => {
        if (step === STEPS.CALIBRATE) {
            setStep(STEPS.ANIMATION)
        } else {
            previousScreen()
        }
    }

    return(
        <>
            <Box sx={{
                position: "absolute",
                zIndex: 9999999,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0,0,0,0.5)",
                width: "100vw",
                height: "90vh",
            }}>
                {step === STEPS.ANIMATION && <Animation nextStep={nextStep} />}
                {step === STEPS.CALIBRATE && <Calibrate startTutorial={startTutorial} />}
            </Box>
            <GoBack onClick={previousStep}/>
        </>
    )
}