import { Box, Typography } from "@mui/material"

import inclinaison from "../../../../assets/animations/inclinaison.gif"

export const Animation = ({
    nextStep
}) => {

    return(
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
            flexDirection: "column"
        }}>
            <img
                src={inclinaison}
                style={{
                    width: "70vw",
                    marginBottom: "20px"
                }}
            />
            <Box sx={{
                width: "80%"
            }}>
                <Typography
                    color="white"
                    fontSize={"18px"}
                >
                    Posez votre téléphone au sol contre un mur et réglez son inclinaison
                </Typography>
            </Box>
            <Box sx={{
                width: "90px",
                height: "90px",
                borderRadius: "100px",
                margin: "10px",
                border: "3px white dashed",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Box 
                    onClick={nextStep}
                    sx={{
                        backgroundColor: "white",
                        width: "80%",
                        height: "80%",
                        borderRadius: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Typography
                        fontSize="18px"
                        fontWeight="bold"
                    >
                        GO
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}