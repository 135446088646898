import { useState } from "react"

import { Box } from "@mui/material"

import { GuideBox } from "./GuideBox"

import { GoBack } from "../../../molecules/GoBack"

import { GUIDELINES } from "./utils/constants"

export const Guidelines = ({
    nextStep,
    isInclination    
}) => {
    const [guideline, setGuideline] = useState(GUIDELINES.PROCESS)
    
    const nextGuideline = () => { 
        if (guideline === GUIDELINES.PROCESS) {
            setGuideline(GUIDELINES.CLOTHING)
        } else if (guideline === GUIDELINES.CLOTHING) {
            setGuideline(GUIDELINES.VOLUME)
        } else if (guideline === GUIDELINES.VOLUME) {
            nextStep()
        }
    }

    const previousGuideline = () => {
        if (guideline === GUIDELINES.CLOTHING) {
            setGuideline(GUIDELINES.PROCESS)
        } else if (guideline === GUIDELINES.VOLUME) {
            setGuideline(GUIDELINES.CLOTHING)
        }
    }

    return(
        <>
            <GoBack onClick={previousGuideline} />
            <Box sx={{
                display: "flex",
                paddingTop: "30vh",
                flexDirection: "column",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
                backgroundColor: "white"
            }}>
                <GuideBox
                    guideline={guideline}
                    nextGuideline={nextGuideline}
                    isInclination={isInclination}
                />
            </Box>
        </>
    )
}