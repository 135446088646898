import { Box, Typography } from "@mui/material"

import { Outcome } from "./outcome"
import { Loading } from "./loading"
import { useEffect, useState } from "react";

export const Results = ({ type }) => {
    const [loading, setLoading] = useState(true);
    const [loadingValue, setLoadingValue] = useState(0);
    const [showOutcome, setShowOutcome] = useState(false);
    
    useEffect(() => {
        setLoading(type === "loading")
    }, [type])

    useEffect(() => {
        if (loadingValue === 100) {
            setTimeout(() => setShowOutcome(true), 300);
        }
    }, [loadingValue]);

    return(
        <Box sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            position: "absolute",
            zIndex: 999999999999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <Box sx={{
                width: "80vw",
                height: "40vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                backgroundColor: "white",
                borderRadius: "20px"
            }}>
                {!showOutcome ? <Loading loading={loading} loadingValue={loadingValue} setLoadingValue={setLoadingValue} /> : <Outcome type={type} />}
            </Box>
        </Box>
    )
}