import { VolumeUp, CameraAlt, Person } from '@mui/icons-material';

import guideline_people from "../../../../assets/img/guideline_people.svg"
import guideline_camera from "../../../../assets/img/guideline_camera.svg"
import guideline_sound from "../../../../assets/img/guideline_sound.svg"

export const GUIDELINES = {
    PROCESS: {
        index: 1,
        body: "Nous allons prendre une photo de face puis de profil",
        icon: guideline_camera
    },
    CLOTHING: {
        index: 2,
        title: "Clothes",
        body: "Pour plus de précision, portez des vêtements proches du corps",
        icon: guideline_people
    },
    VOLUME: {
        index: 3,
        body: "Augmentez le volume de votre téléphone pour suivre le tutoriel",
        icon: guideline_sound
    }
}